import React from "react";
import RoutingComponent from './routes/routes'
// import "./App.css";

function App() {
  return (
      <RoutingComponent />
  );
}

export default App;
