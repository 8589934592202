import React, { Suspense, lazy, useEffect } from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import history from "../history";

const ReferEarn = lazy(()=> import("../Components/ReferEarn/ReferEarn"));
const TrackingRoute = lazy(()=> import("./trackingRoute"));
const ReturnTrackingRoute =  lazy(()=> import("../Components/AccessoriesReturnOrderPage/ReturnOrderTracking"));
const CompletedOrderRoute = lazy(() => import("./completeOrderRoute"));
const FeedBackForm = lazy(() => import("../Components/feedbackForm/FeedBackForm"));
const OrderHistory = lazy(() => import("../Components/orderHistory/OrderHistory"));
const NotFound = lazy(() => import("../Components/error/error.js"));
const MyProfile = lazy(() => import("../Components/profile/MyProfile.js"));
const FAQ = lazy(() => import("../Components/faqPage/FaqPage.js"));
const GoAppMoney = lazy(() => import("../Components/GoAppMoney/GoAppMoney"));
const MyCars = lazy(()=> import("../Components/myCars/MyCars")); 
const HealthCardListing = lazy(()=> import("../Components/healthCardListing/HealthCardListing"));
const CompletedOrder = lazy(()=> import("../Components/completedOrder/CompletedOrder"));
const HealthCardPage = lazy(()=> import("../Components/healthCardPage/HealthCardPage"));
const ManageAddresses = lazy(() => import("../Components/manageAddresses/ManageAddresses"));
const Obd = lazy(()=>import("../Components/obd/Obd"))
const ObdCarHealth = lazy(()=>import("../Components/ObdCarHealth/ObdCarHealth"))
const TheftAlerts = lazy(()=>import("../Components/ObdSettings/TheftAlert"))
const AddTheftAlerts = lazy(()=>import("../Components/ObdSettings/TheftAlertAddEdit"))
const AddGeofence = lazy(()=>import("../Components/ObdSettings/AddNewGeofence"))
const OBDHomeMain = lazy(()=>import('../Components/ObdMain/ObdMainHomePage'))
const CarInfo = lazy(()=>import('../Components/CarInfo/CarInfo'))

const RoutingComponent = props => {

  useEffect(() =>{
    if(!localStorage.getItem("country")){
      const { apiConfigs } = window;
      const country = apiConfigs && apiConfigs.endpoint == "https://mly.gomechanic.app/" ? "malaysia" : "india";
      const currency = country == "malaysia" ? "RM " : "Rs. ";
      localStorage.setItem("country", country);
      localStorage.setItem("currency", currency);
    }
  },[])

  return (
    <Suspense fallback={<div></div>}>
      <Router history={history}>
        <Switch>
          <Route exact path="/health-card" component={HealthCardListing} />
          <Route exact path="/health-card/:order_id" component={HealthCardPage} />
          <Route exact path="/" render={() => <Redirect to="/order-history" />} />
          <Route exact path="/order-history" component={OrderHistory} />
          <Route exact path="/order/:order_id" component={TrackingRoute} />
          <Route exact path="/completed-order/:order_id" component={CompletedOrderRoute} />
          <Route exact path="/return-order/:order_id" component={ReturnTrackingRoute} />
          <Route exact path="/my-profile" component={MyProfile} />
          <Route exact path="/manage-addresses" component={ManageAddresses} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/goapp-money" component={GoAppMoney} />
          <Route exact path="/my-cars" component={MyCars} />
          <Route exact path='/obd-car-health' component={ObdCarHealth}/>
          <Route exact path='/anti-theft-alerts' component={TheftAlerts}/>
          <Route exact path='/add-geofence' component={AddGeofence}/>
          <Route exact path="/home-obd" render = {
            ()=>{
              if(localStorage.getItem("accessToken")!==null){
               return <OBDHomeMain/>
              }
             else{
                return <OrderHistory />     
            }  
          }}
          /> 
          <Route exact path="/obd" render = {
            ()=>{
              if(localStorage.getItem("accessToken")!==null){
               return <Obd/>
              }
             else{
                return <OrderHistory />     
            }  
          }}
          />
          <Route exact path="/goapp-money" render = {
              ()=>{
                if(localStorage.getItem("accessToken")!==null){
                 return <GoAppMoney/>
                }
               else{
                  return <OrderHistory />     
              }  
           }    
          } 
          />
          <Route exact path="/refer-earn" render = {
             ()=>{
                if(localStorage.getItem("accessToken")!==null){
                 return <ReferEarn/>
                }
               else{
                  return <OrderHistory />     
              }  
           }  
          } 
          />
              <Route exact path="/:phone" render ={
            ()=>{
              var urlNumber = window.location.pathname.split('/')[1]; 
              var phoneno = /^\d{10}$/;
              if(urlNumber.match(phoneno)){
                if(localStorage.getItem("accessToken")!==null){
                 return <Redirect to="/order-history" />
                }
                  return <OrderHistory />     
              } else {
                return <Redirect to="/order-history" />
              }  
           }
          }/>
          <Route exact path="/car-info" render={
            () => {
              if (localStorage.getItem("accessToken") !== null) {
                return <CarInfo />
              }
              else {
                return <OrderHistory />
              }
            }}
          /> 
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default RoutingComponent;
